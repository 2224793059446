<template>
  <div class="about">
    <div v-if="serviceHtml">
      <!-- Page Title Area Start -->
      <section
        class="page-title-area bg-image ptb--80"
        :style="`background-image:url(${serviceHtml.service_img_link})`"
      >
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h1 class="page-title">{{ serviceHtml.service_title }}</h1>
            </div>
          </div>
        </div>
      </section>
      <!-- Page Title Area End -->

      <!-- Breadcrumb Area Start -->
      <div class="breadcrumb-area mb--60">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="breadcrumb">
                <li><router-link to="/">Home</router-link></li>
                <li class="current"><span>About Us</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- Breadcrumb Area End -->
      <!-- Main Content Wrapper Start -->
      <div v-html="serviceHtml.service_pagedetails"></div>
    </div>
    <!-- <h1 v-else>404</h1> -->
  </div>
</template>
<script>
import { API_URL } from "../router/api";
export default {
  name: "About",
  components: {},
  data() {
    return {
      serviceHtml: null,
      paramsUrl: null,
    };
  },
  created() {
    setTimeout(() => {
      this.insidePage();
    }, 1000);
  },
  watch: {
    $route: "insidePage",
  },
  methods: {
    insidePage() {
      this.axios
        .post(`${API_URL}website_service_list`, { data: "about-us" })
        .then((response) => {
          let obj = response.data;
          this.serviceHtml = obj;
        });
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
